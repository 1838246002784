@import "libs/theme/orion-theme";

@mixin modal-theme($orion-theme) {
  o-applic-modal,
  o-settings-form,
  o-historic-dialog,
  o-eordering-dialog,
  o-transfert-ti-dialog,
  o-web-tek-dialog,
  o-pym-modal,
  o-diagnostic-modal,
  o-contract-modal,
  o-info-dialog,
  o-add-news-modal,
  o-confirm-dialog,
  o-cm-preview,
  o-disclaimer,
  o-release-info,
  o-bookmark-edit,
  o-news-modal,
  o-ipc-preview,
  o-support-modal,
  o-tutorial-modal,
  o-training-modal,
  o-user-edit {
    .icon {
      color: white;

      &:hover,
      &:focus {
        background: color-accent($orion-theme);
      }
    }

    .label {
      color: foreground-color($orion-theme, text);
    }

    .errorColor {
      color: color-red($orion-theme) !important;
    }
  }

  //TODO: Check if it is used in edistribution
  .cdk-overlay-pane-select-search {
    .mat-mdc-select-content {
      mat-option:first-of-type {
        background: background-color($orion-theme, matOptionFirst) !important;
      }
    }
  }

  o-disclaimer,
  o-training-modal,
  o-support-modal {
    a,
    a * {
      color: color-link($orion-theme) !important;
    }
  }
}
