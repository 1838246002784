@import "libs/theme/orion-theme";

@mixin toc-item-header-theme($orion-theme) {
  o-toc-item-header {
    background: background-color($orion-theme, background);

    .empty-message {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .mat-badge-content {
      background: color-accent($orion-theme) !important;
      bottom: -4px !important;
      font-size: 14px !important;
    }
  }
}
