@import "libs/theme/orion-theme";

@mixin ipc-card-container-theme($orion-theme) {
  o-ipc-card-container {
    > div {
      border-top: solid 1px blk-color($orion-theme, 0.12);
    }

    .ap-actions .label {
      color: color-primary($orion-theme);
    }

    .mdc-tab {
      min-width: unset;
      padding: 0;
    }
  }
}
