// Shared theme files
@import "libs/theme/modal-theme";
@import "libs/theme/shared-theme";

// Viewer theme files
@import "apps/viewer/src/app/content-provider/content-provider-theme";
@import "apps/viewer/src/app/home/settings-home/settings-home-theme";
@import "apps/viewer/src/app/fulltext-module/fulltext/fulltext-theme";
@import "apps/viewer/src/app/content-provider/content-style/orion2/content-theme";
@import "apps/viewer/src/app/toc/toc-theme";
@import "apps/viewer/src/app/shared-module/orion-card/orion-card-theme";
@import "apps/viewer/src/app/ipc-module/ipc-toc/ipc-toc-theme";
@import "apps/viewer/src/app/ipc-module/ipc-card/ipc-card-theme";
@import "apps/viewer/src/app/ipc-module/ipc-card-container/ipc-card-container-theme";
@import "apps/viewer/src/app/ipc-module/ipc-detail/ipc-details-theme";
@import "apps/viewer/src/app/ipc-module/ipc/ipc-theme";
@import "apps/viewer/src/app/search-result-module/search-result/search-result-theme";
@import "apps/viewer/src/app/shared-module/task-card/task-card-theme";
@import "apps/viewer/src/app/shared-module/part-card/part-card-theme";
@import "apps/viewer/src/app/fulltext-module/fulltext/s1000d-manuals/msm-als-theme";
@import "apps/viewer/src/app/shared-module/revision/revision-theme";
@import "apps/viewer/src/app/media-module/inline-step-thumbnail/inline-step-thumbnail-theme";
@import "apps/viewer/src/app/media-module/player/player-theme";
@import "apps/viewer/src/app/toc-items/shopping-basket/shopping-list/shopping-list-theme";
@import "apps/viewer/src/app/auth-login/auth-login-theme";
@import "apps/viewer/src/app/inspection-modal/inspection-modal-theme";
@import "apps/viewer/src/app/shared-module/confirm-dialog/confirm-dialog-theme";
@import "apps/viewer/src/app/toc-items/note-module/note/note-theme";
@import "apps/viewer/src/app/content-provider/content-style/legacy/table-theme";
@import "apps/viewer/src/app/content-provider/content-style/legacy/xslt-theme";
@import "apps/viewer/src/app/settings/settings-theme";
@import "apps/viewer/src/app/shared-module/card/card-theme";
@import "apps/viewer/src/app/shared-module/card-pub/card-pub-theme";
@import "apps/viewer/src/app/shared-module/card-contract/card-contract-theme";
@import "apps/viewer/src/app/stepbystep-module/player-bar/player-bar-theme";
@import "apps/viewer/src/app/shared-module/search-bar/search-bar-theme";
@import "apps/viewer/src/app/media-module/legend/legend-theme";
@import "apps/viewer/src/app/thumbs-module/thumbs/thumbs-theme";
@import "apps/viewer/src/app/toc-items/historic-module/historic-dialog/historic-dialog-theme";
@import "apps/viewer/src/app/toc-items/list/toc-item-list-theme";
@import "apps/viewer/src/app/home/search-products/search-products-theme";
@import "apps/viewer/src/app/home/search-contracts/search-contracts-theme";
@import "apps/viewer/src/app/search-result-module/faceted-search/faceted-search-theme";
@import "apps/viewer/src/app/not-found/not-found-theme";
@import "apps/viewer/src/app/toc-items/toc-item-header/toc-item-header-theme";
@import "apps/viewer/src/app/news/card-news/card-news-theme";
@import "apps/viewer/src/app/home/contract-list/contract-list-theme";
@import "apps/viewer/src/app/home/online-products/online-products-theme";
@import "apps/viewer/src/app/home/home-tabs/home-tabs-theme";
@import "apps/viewer/src/app/layout/layout-theme";
@import "apps/viewer/src/app/help-module/help/help-theme";
@import "apps/viewer/src/app/toc-items/inspection-module/inspection-list/inspection-list-theme";
@import "apps/viewer/src/app/toc-items/bookmark-module/bookmark-import-result-dialog/bookmark-import-result-dialog-theme";
@import "apps/viewer/src/app/help-module/disclaimer/disclaimer-theme";

@mixin viewer-theme($theme-or-color-config) {
  // Shared themes
  @include modal-theme($theme-or-color-config);
  @include shared-theme($theme-or-color-config);

  // Viewer themes
  @include content-provider-theme($theme-or-color-config);
  @include card-theme($theme-or-color-config);
  @include card-pub-theme($theme-or-color-config);
  @include card-contract-theme($theme-or-color-config);
  @include settings-home-theme($theme-or-color-config);
  @include fulltext-theme($theme-or-color-config);
  @include content-theme($theme-or-color-config);
  @include toc-theme($theme-or-color-config);
  @include orion-card-theme($theme-or-color-config);
  @include ipc-toc-theme($theme-or-color-config);
  @include ipc-details-theme($theme-or-color-config);
  @include ipc-theme($theme-or-color-config);
  @include ipc-card-theme($theme-or-color-config);
  @include ipc-card-container-theme($theme-or-color-config);
  @include search-result-theme($theme-or-color-config);
  @include task-card-theme($theme-or-color-config);
  @include part-card-theme($theme-or-color-config);
  @include msm-als-theme($theme-or-color-config);
  @include revision-theme($theme-or-color-config);
  @include inline-step-thumbnail-theme($theme-or-color-config);
  @include player-theme($theme-or-color-config);
  @include shopping-list-theme($theme-or-color-config);
  @include auth-login-theme($theme-or-color-config);
  @include inspection-modal-theme($theme-or-color-config);
  @include confirm-dialog-theme($theme-or-color-config);
  @include note-theme($theme-or-color-config);
  @include table-theme($theme-or-color-config);
  @include xslt-theme($theme-or-color-config);
  @include settings-theme($theme-or-color-config);
  @include player-bar-theme($theme-or-color-config);
  @include search-bar-theme($theme-or-color-config);
  @include legend-theme($theme-or-color-config);
  @include thumbs-theme($theme-or-color-config);
  @include historic-dialog-theme($theme-or-color-config);
  @include toc-item-list-theme($theme-or-color-config);
  @include search-products-theme($theme-or-color-config);
  @include search-contracts-theme($theme-or-color-config);
  @include faceted-search-theme($theme-or-color-config);
  @include not-found-theme($theme-or-color-config);
  @include toc-item-header-theme($theme-or-color-config);
  @include card-news-theme($theme-or-color-config);
  @include contract-list-theme($theme-or-color-config);
  @include online-products-theme($theme-or-color-config);
  @include home-tabs-theme($theme-or-color-config);
  @include layout-theme($theme-or-color-config);
  @include help-theme($theme-or-color-config);
  @include inspection-list-theme($theme-or-color-config);
  @include bookmark-import-result-dialog-theme($theme-or-color-config);
  @include disclaimer-theme($theme-or-color-config);
}

body {
  @include angular-material-theme($orion-light-theme);
  @include viewer-theme($orion-light-theme);
  background: background-color($orion-light-theme, background);

  &.orion-dark {
    @media screen {
      @include viewer-theme($orion-dark-theme);
      @include angular-material-color($orion-dark-theme);
      background: background-color($orion-dark-theme, background);
    }
  }
}
