@import "libs/theme/orion-theme";

@mixin ipc-theme($orion-theme) {
  o-ipc {
    .container {
      button {
        &.toc-toggle {
          background: color-primary($orion-theme);
        }
      }
    }

    .ipc-title {
      color: foreground-rgb-color($orion-theme, 0.47);
    }
  }
}
