@import "libs/theme/orion-theme";

@mixin bookmark-import-result-dialog-theme($orion-theme) {
  .success-text {
    color: color-green($orion-theme);
  }

  .errors-text {
    color: color-red($orion-theme);
  }
}
