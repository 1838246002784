@import "libs/theme/orion-theme";

@mixin thumbs-theme($orion-theme) {
  @media screen {
    .thumb {
      &.current {
        border-top: solid 5px color-accent($orion-theme) !important;
      }
    }
  }
}
