@import "libs/theme/orion-theme";

@mixin historic-dialog-theme($orion-theme) {
  o-historic-dialog {
    .mat-mdc-tab-header {
      background: background-color($orion-theme, background);
    }

    o-history-list {
      mat-table {
        background: background-color($orion-theme, background) !important;
      }
    }
  }
}
