@import "libs/theme/orion-theme";

@mixin card-news-theme($orion-theme) {
  o-card-news {
    .date {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .read-dot {
      background-color: color-green($orion-theme);
    }
  }
}
