@import "libs/theme/orion-theme";
@mixin faceted-search-theme($orion-theme) {
  o-faceted-search {
    .facet-title {
      background-color: background-search($orion-theme) !important;

      &:hover {
        background-color: solid-hover($orion-theme);
      }

      &.mat-expanded {
        border-bottom: solid 1px background-color($orion-theme, hover);
      }
    }
  }
}
