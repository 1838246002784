@import "libs/theme/orion-theme";

@mixin search-products-theme($orion-theme) {
  o-search-products {
    background-color: background-color($orion-theme, background);

    .header {
      background-color: color-accent($orion-theme);
      color: text-with-background($orion-theme);
    }
  }
}
