@import "libs/theme/orion-theme";

@mixin inspection-modal-theme($orion-theme) {
  o-inspection-modal {
    h1 {
      color: color-primary($orion-theme);
    }

    .choice {
      div {
        color: color-primary($orion-theme);
      }
    }

    .header {
      .title {
        background: color-primary($orion-theme);
      }

      .icon {
        &:hover,
        &:focus {
          background: color-accent($orion-theme);
        }
      }
    }

    mat-error {
      color: color-red($orion-theme) !important;
    }
  }
}
