@import "libs/theme/orion-theme";

@mixin task-card-theme($orion-theme) {
  o-task-card {
    a,
    .uncolor {
      color: foreground-rgb-color($orion-theme, 0.87);
    }

    .ref {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .more-info {
      color: color-primary($orion-theme);
    }

    .span_links {
      color: color-link($orion-theme);

      &.mpPn {
        color: color-green($orion-theme);
      }
    }

    .red {
      color: color-red($orion-theme);
    }

    .green {
      color: color-green($orion-theme);
    }
  }
}
