@import "libs/theme/orion-theme";

@mixin toc-item-list-theme($orion-theme) {
  o-toc-item-list {
    mat-form-field,
    .mat-mdc-tab-header,
    .select-unselect-actions {
      background: background-color($orion-theme, background);
    }

    .select-unselect-actions {
      color: color-link($orion-theme);
    }
  }
}
