@import "libs/theme/orion-theme";

@mixin shopping-list-theme($orion-theme) {
  o-shopping-list {
    .shopping__empty {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .basket__quantity {
      color: color-primary($orion-theme);
    }

    .shopping__header {
      background: background-color($orion-theme, background);
    }
  }

  .shopping__actions--file-eOrderingIcon {
    color: $e-ordering-color !important;
  }
}
