@import "libs/theme/orion-theme";

@mixin settings-theme($orion-theme) {
  o-settings {
    .header {
      .icon {
        ::ng-deep svg {
          &:hover {
            fill: color-accent($orion-theme);
          }
        }
      }
    }

    .header-login {
      background: color-accent($orion-theme);

      div {
        color: text-with-background($orion-theme);
      }
    }

    .item-clickable {
      &:hover {
        background-color: background-color($orion-theme, hover);
      }

      a {
        color: foreground-color($orion-theme, text);
      }
    }

    .not-clickable {
      svg {
        fill: foreground-rgb-color($orion-theme, 0.3);
      }
    }

    .text-icon {
      background-color: color-icons($orion-theme) !important;
      color: text-with-background($orion-theme) !important;
    }
  }
}
