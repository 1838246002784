@import "libs/theme/orion-theme";

@mixin revision-theme($orion-theme) {
  o-revision-mark {
    .blue {
      background: color-primary($orion-theme) !important;
      color: text-with-background($orion-theme) !important;
    }

    .green {
      background-color: color-green($orion-theme) !important;
      color: text-with-background($orion-theme) !important;
    }

    .red {
      background-color: color-red($orion-theme) !important;
      color: text-with-background($orion-theme) !important;
    }

    .orange {
      background-color: $color-warn;
      color: text-with-background($orion-theme) !important;
    }
  }
}
