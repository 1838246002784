@import "libs/theme/orion-theme";

@mixin note-theme($orion-theme) {
  o-note {
    .note_infos,
    .note--te {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    mat-form-field {
      &:not(.mat-focused) {
        .mat-form-field-label {
          color: blk-color($orion-theme, 0.54) !important;
        }

        .mat-placeholder-required {
          color: blk-color($orion-theme, 0.54) !important;
        }
      }

      &.mat-focused {
        .mat-form-field-label {
          color: color-primary($orion-theme) !important;
        }

        .mat-placeholder-required {
          color: color-primary($orion-theme) !important;
        }
      }

      .mat-form-field-ripple {
        color: blk-color($orion-theme, 0.54) !important;
      }

      mat-error {
        color: color-red($orion-theme) !important;
      }
    }
  }
}
