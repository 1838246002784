@import "libs/theme/orion-theme";

@mixin part-card-theme($orion-theme) {
  o-part-card {
    a,
    .uncolor {
      color: foreground-rgb-color($orion-theme, 0.87);
    }

    .ref {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .more-info {
      color: color-primary($orion-theme);
    }

    .span_links {
      color: color-link($orion-theme);
    }

    mat-card,
    .shop-col {
      .mat-badge-content {
        background: color-accent($orion-theme) !important;
        bottom: -4px !important;
        font-size: 14px !important;
      }

      .is-in-basket .mat-badge-content {
        background: color-green($orion-theme) !important;
      }
    }
  }
}
