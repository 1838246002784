@import "libs/theme/orion-theme";

@mixin help-theme($orion-theme) {
  o-help {
    mat-card:hover,
    mat-card:active {
      background-color: background-color($orion-theme, hover) !important;
    }
  }
}
