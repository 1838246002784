@import "libs/theme/orion-theme";

@mixin legend-theme($orion-theme) {
  o-legend {
    background: background-color($orion-theme, background);

    .legendRowId.legend-row.selected {
      background-color: background-color($orion-theme, hover);
      color: foreground-color($orion-theme, text);

      &.changeMarkClass * {
        background-color: $color-changeMark-selected !important;
      }
    }

    .header {
      background-color: color-accent($orion-theme);
      color: $color-white;
      display: flex;

      h1 {
        color: text-with-background($orion-theme);
      }
    }

    .span_links {
      color: color-link($orion-theme);
    }

    table td {
      border: none;
      border-bottom: solid 1px foreground-rgb-color($orion-theme, 0.1);

      &.changeMarkClass {
        border-bottom-color: foreground-rgb-color($orion-light-theme, 0.1);
      }
    }
  }
}
