@import "libs/theme/orion-theme";

@mixin content-provider-theme($orion-theme) {
  o-content-provider {
    background: background-color($orion-theme, background);

    .notes--title {
      color: color-primary($orion-theme);
    }

    .note-sidenav {
      background: background-color($orion-theme, background);
    }

    // SPEC: We don't want to display non-applicable css in print
    @media screen {
      &.not-applicable {
        background: color-nonApplic($orion-theme);
      }

      .header__not-applicable {
        color: black !important;

        mat-icon {
          color: black !important;
        }
      }

      .non-applicable-content,
      .preprint-media,
      .preprint-media-side-by-side {
        *:not(.chip):not(.mat-badge-content):not(button.mat-primary) {
          color: black !important;
        }

        mat-ink-bar {
          background: black !important;
        }

        .mat-mdc-tab-header-pagination-chevron,
        .header-card {
          border-color: black !important;
        }

        .mat-drawer-container,
        .mat-sidenav-content,
        .note-sidenav,
        #wrapper,
        .overview,
        .content,
        .header_wrapper,
        .header-amm,
        .notesList,
        .mat-mdc-card,
        o-ipc o-svg,
        .media-infos,
        o-ipc-toc,
        o-ipc-toc .mode_button,
        o-ipc-toc .grid-header,
        o-ipc-card mat-card,
        o-ipc-detail,
        o-ipc-detail mat-card,
        o-ipc-detail .ipc_card,
        .noteContainer .note-sidenav,
        .mat-mdc-tab-header-pagination,
        o-thumbs,
        o-ipc-view,
        .media-header,
        .nav-buttons {
          background: color-nonApplic($orion-theme) !important;
        }

        o-svg svg {
          *[fill="#fff"] {
            fill: color-nonApplic($orion-theme);
          }

          *[stroke="#fff"] {
            stroke: color-nonApplic($orion-theme);
          }
        }

        .notesList {
          .mat-form-field-disabled {
            input:disabled,
            textarea:disabled {
              opacity: 1;
              //following is needed to fix Safari/iOS bug for hidden form fields:text color is lighter, even if set to pure black;
              -webkit-text-fill-color: $color-black;
            }
          }
        }

        .changeMarkClass:not(.marked-text),
        .changemarkCarre {
          background-color: $color-changeMark-disabled !important;
        }

        .NOTE_BEFORE_CLASS,
        .NOTE_CLASS_AHX4,
        .NOTE_CLASS_AHX4 > span,
        .NOTE_CLASS,
        .NOTE_BEFORE_CLASS > span {
          background-color: $color-note-disabled !important;
          border-color: $color-note-border-disabled !important;
        }

        .WARNING_BEFORE_CLASS,
        .WARNING_CLASS_AHX4,
        .WARNING_CLASS_AHX4 > span,
        .WARNING_CLASS,
        .WARNING_BEFORE_CLASS > span {
          background-color: $color-err-disabled !important;
          border-color: $color-err-border-disabled !important;
        }

        .CAUTION_BEFORE_CLASS,
        .CAUTION_CLASS,
        .CAUTION_CLASS_AHX4,
        .CAUTION_CLASS_AHX4 > span,
        .CAUTION_BEFORE_CLASS > span {
          background-color: $color-warn-disabled !important;
          border-color: $color-warn-border-disabled !important;
        }
      }
    }
  }
}
