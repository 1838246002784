@import "libs/theme/orion-theme";

@mixin settings-home-theme($orion-theme) {
  o-settings-home {
    .item-clickable {
      &:hover {
        background-color: background-color($orion-theme, hover);
      }

      a {
        color: foreground-color($orion-theme, text);
      }
    }

    .header {
      background: color-accent($orion-theme);

      div {
        color: text-with-background($orion-theme);
      }

      &.degraded {
        background: $color-grey1;
      }
    }
  }
}
