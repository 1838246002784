@import "libs/theme/orion-theme";
@mixin player-theme($orion-theme) {
  o-player {
    .player-container {
      .switch-view-button-container {
        button {
          background-color: background-color($orion-theme, background);
        }
      }
    }
  }
}
