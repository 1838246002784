@import "libs/theme/orion-theme";

@mixin contract-list-theme($orion-theme) {
  o-contract-list {
    mat-chip {
      background: color-accent($orion-theme) !important;
      color: text-with-background($orion-theme) !important;

      .mat-mdc-chip-remove {
        color: text-with-background($orion-theme) !important;
      }
    }
  }
}
