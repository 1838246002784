@import "libs/theme/orion-theme";

@mixin toc-theme($orion-theme) {
  o-toc {
    .selected-node {
      .mat-mdc-card {
        background-color: background-color($orion-theme, hover);
      }
    }

    .parent-node {
      background: color-accent($orion-theme);

      h3 {
        color: text-with-background($orion-theme);
      }
    }

    .folder {
      &:active,
      &:hover {
        background-color: background-color($orion-theme, hover);
      }
    }
  }
}
