@use "@angular/material" as mat;
@import "libs/theme/orion-theme";

@mixin content-theme($orion-theme) {
  .content {
    h2 {
      color: color-primary($orion-theme);
    }

    h3,
    .end-document {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    #loap_show_scrollable.visible {
      background: color-opacity(color-primary($orion-theme), 0.3);
    }

    #wrapper {
      .DU_INTRO .ListeFromAMMSDSStylesheet.prclist1 {
        > .listChild {
          > .listChildNumerotation::before {
            color: color-primary($orion-theme);
          }

          > .listChildText > .TITLE_LEVEL_PRCITEM_CLASS.TOC {
            color: color-primary($orion-theme);
          }
        }
      }
    }

    .odd_table_class tr {
      border-bottom: 1px solid color-primary($orion-theme);
    }

    #title_id > span,
    #title_id {
      color: color-primary($orion-theme);
    }

    .span_links,
    .FIGITEM_CLASS,
    .FIGITEM_CLASS span,
    .FIGITEM_HEAD_CLASS {
      color: color-link($orion-theme);
    }
    //SPEC: ChangeMarkClass add a green background
    // We must use the light mode on it for increase readability
    .changeMarkClass {
      .span_links,
      &.span_links,
      .FIGITEM_CLASS,
      .FIGITEM_CLASS span,
      .FIGITEM_HEAD_CLASS,
      .AHX4_ref_hotspot {
        color: color-link($orion-light-theme);
      }
    }

    .AHX4_procedural_step_label {
      table thead th {
        color: foreground-color($orion-theme, text);
      }
    }
  }

  .DU_CONTENT {
    .AHX4_ref_hotspot,
    .AHX4_ref_tools,
    .AHX4_ref_class,
    .AHX4_links_Enriched_class {
      color: color-link($orion-theme);
    }

    .AHX4_SPAN .AHX4_links_Enriched_class {
      color: color-link($orion-light-theme);
    }

    .AHX4_procedural_step_label {
      ::ng-deep {
        table thead th {
          background: color-accent($orion-theme);
          border: 1px solid color-accent($orion-theme);
        }
      }
    }

    .AHX4_proced_title_class {
      font-weight: bold;
    }

    table::-webkit-scrollbar-thumb {
      background: color-accent($orion-theme);
    }
  }

  .span_links {
    color: color-link($orion-theme);
  }

  .mat-icon.on_background {
    color: text-with-background($orion-theme) !important;
  }

  .mat-icon.marked {
    color: color-primary($orion-theme) !important;
  }

  .mat-icon.black_primary {
    color: color-choice($orion-theme, $color-blk-87, mat.get-color-from-palette($orion-dark-primary)) !important;
  }

  o-media-container,
  o-media-switch,
  o-notes-button {
    .mat-icon.mat-primary {
      color: color-primary($orion-theme) !important;
    }
  }
}
