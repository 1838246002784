@import "libs/theme/orion-theme";

@mixin search-bar-theme($orion-theme) {
  o-search-bar {
    input {
      background: background-search($orion-theme);
      color: foreground-color($orion-theme, text);
    }
  }
}
