@import "libs/theme/orion-theme";

@mixin card-contract-theme($orion-theme) {
  o-card-contract {
    mat-card {
      &:hover,
      &:active {
        background-color: background-color($orion-theme, hover) !important;
      }
    }
  }
}
