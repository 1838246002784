@import "libs/theme/orion-theme";

@mixin table-theme($orion-theme) {
  table.msm_refdoc_table_class {
    color: color-accent($orion-theme);
  }

  .odd_table_class thead th,
  .tr_like_th_class,
  table.csn_popup_body_table thead th {
    color: color-primary($orion-theme);
  }

  table.csn_popup_body_table td.td_disabled_class,
  td.last_column,
  td.first_column {
    background: background-color($orion-theme, hover);
  }
}
