@import "libs/theme/orion-theme";

@mixin card-pub-theme($orion-theme) {
  o-card-pub {
    .consultable:hover,
    .consultable:active {
      background-color: background-color($orion-theme, hover) !important;
    }
  }
}
