@import "libs/theme/orion-theme";

@mixin ipc-toc-theme($orion-theme) {
  o-ipc-toc {
    background: background-color($orion-theme, background);

    .mode_button {
      background: background-color($orion-theme, background);
      color: color-primary($orion-theme);
    }

    .end-document {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .grid-header {
      background: adapt-color($orion-theme, $color-wht-87);
    }
  }
}
